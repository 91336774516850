@import "~highlight.js/styles/github.css";

/* You can add global styles to this file, and also import other style files */
$navbar-height: 30px;
.body-wrapper{
    min-height:calc(100vh-#{$navbar-height}); 
}

.btn-primary, .bg-primary{
    // background: linear-gradient(90deg, rgba(213,53,53,20) 0%, rgba(213,53,53,20) 100%);
    background: rgb(213,53,53);
    background: linear-gradient(90deg, rgba(213,53,53,20) 0%, rgba(213,53,53,20) 100%);
    border: none !important;
    transition: background 0.3s ease!important;
}

.btn-primary:hover, 
.button-primary-override:active,
.button-primary-override:focus,
.button-primary-override:visited{
    background: linear-gradient(90deg, #680303 0%, #680303 100%)!important;
}
/* Enter your custom styles */
/* josefin-sans-regular - latin */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/josefin-sans.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/assets/fonts/josefin-sans.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/josefin-sans.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/josefin-sans.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/josefin-sans.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/josefin-sans.svg#JosefinSans') format('svg'); /* Legacy iOS */
  }
  
  .adcolabs-text-glow {
    // animation: glow-animation 5s infinite alternate;
  }


@keyframes glow-animation {
    0% {
        text-shadow: 0 0 5px #AE2B2B, 0 0 5px #f2f2f2, 0 0 15px #f2f2f2, 0 0 20px #f2f2f2;
    }

    50% {
        text-shadow: 0 0 10px #AE2B2B, 0 0 10px #f2f2f2, 0 0 30px #f2f2f2, 0 0 40px #f2f2f2;
    }

    100% {
        text-shadow: 0 0 5px #AE2B2B, 0 0 5px #f2f2f2, 0 0 15px #f2f2f2, 0 0 20px #f2f2f2;
    }
}

.nav-item a:focus{
    color:#AE2B2B;
}


.nav-item a:active{
    color:#AE2B2B;
}

.nav-link a:active{
    color:linear-gradient(90deg, rgb(213, 53, 53) 0%, rgb(213, 53, 53) 100%)
}

.ngx-pagination .current {
  padding:.1875rem .625rem;
  background: #d53535 !important;
  color:#fefefe;
  cursor:default;
  border-radius: 4px;
}
